body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 640px) {
    .mobile {
        @apply mobile;
    }
}

@media (min-width: 641px) {
    .desktop {
        @apply desktop;
    }
}


.colorPaletteFromLogo {
    color: #0498f1;
    color: #43c3fb;
    color: #fbc8b8;
    color: #7aafd3;
    color: #b9c0ca;
    color: #ccc4cc;
    color: #429cdc;
    color: #dcb4b4;
}

